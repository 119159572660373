<template>
  <div class="payment-page-wrapper">
    <div class="container">
      <h1>Способы оплаты</h1>

      <p class="underline-text">
        Оплата доступна любым удобным для вас способом:
      </p>

      <h2 class="underline-text">Банковской картой</h2>

      <p class="section-text">
        Для выбора оплаты услуг с помощью банковской карты на соответствующей
        странице необходимо нажать кнопку «Оплата заказа банковской картой».
        Оплата происходит через ПАО СБЕРБАНК с использованием Банковских карт
        следующих платежных систем:
      </p>

      <div class="payment-methods">
        <div class="item-payment">
          <svg
            width="37px"
            height="10px"
            viewBox="0 0 37 10"
            version="1.1"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="pay-mir">
              <path
                d="M9.87126 0.00436691L9.87126 0L13.1315 0L13.1315 10L9.87126 10L9.87126 4.0655L9.59957 4.0655L7.69777 10L5.43372 10L3.53192 4.06114L3.26023 4.06114L3.26023 10L0 10L0 0L3.26023 0C3.26023 0 4.69111 -0.00436672 5.07147 1.31004C5.42466 2.53712 6.4299 5.93886 6.4299 5.93886L6.70159 5.93886C6.72876 5.84716 7.71136 2.51965 8.06002 1.31441C8.43961 0.00266028 9.86096 0.00435463 9.87126 0.00436691ZM21.7802 0C21.7802 0 20.8247 0.0829695 20.3765 1.04804L18.0671 5.93886L17.7954 5.93886L17.7954 0L14.5352 0L14.5352 10L17.6143 10C17.6143 10 18.615 9.91266 19.0633 8.95197L21.3274 4.06114L21.599 4.06114L21.599 10L24.8593 10L24.8593 0L21.7802 0ZM26.3083 10L26.3083 4.54148L36.4286 4.54148C35.9441 5.86463 34.64 6.81223 33.1004 6.81223L29.5685 6.81223L29.5685 10L26.3083 10Z"
                id="Shape"
                fill="#4DB45E"
                fill-rule="evenodd"
                stroke="none"
              />
              <path
                d="M7.20436 -1.11023e-16L0 -1.11023e-16C0.359993 1.98784 1.83146 3.58359 3.73493 4.10334L3.73493 4.10334C4.16692 4.22188 4.62141 4.28571 5.0894 4.28571L10.6423 4.28571C10.6918 4.04863 10.7143 3.80699 10.7143 3.55623C10.7143 1.59119 9.14382 -1.11023e-16 7.20436 -1.11023e-16Z"
                transform="translate(25.71428 0)"
                id="Path"
                fill="#03A6DC"
                stroke="none"
              />
            </g>
          </svg>
          <p>МИР</p>
        </div>
        <div class="item-payment">
          <svg
            width="45px"
            height="16px"
            viewBox="0 0 45 16"
            version="1.1"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="pay-visa">
              <path
                d="M28.3201 0.597807C27.617 0.31883 26.5037 0 25.117 0C21.5819 0 19.1014 1.91298 19.0819 4.66289C19.0624 6.69544 20.8592 7.81134 22.2069 8.48886C23.5936 9.1863 24.0624 9.62469 24.0624 10.2424C24.0428 11.179 22.9491 11.6174 21.9139 11.6174C20.4882 11.6174 19.7264 11.3982 18.5546 10.8602L18.0858 10.641L17.6171 13.8093C18.4569 14.2079 19.9999 14.5466 21.6014 14.5666C25.3514 14.5666 27.7928 12.6735 27.8123 9.74425C27.8319 8.13018 26.8748 6.91463 24.8045 5.89836C23.5545 5.2607 22.7928 4.82231 22.7928 4.16472C22.8124 3.56692 23.4374 2.94918 24.8436 2.94918C25.9959 2.90933 26.8553 3.20823 27.4998 3.48721L27.8319 3.64662L28.3201 0.597807ZM5.332 9.86381L8.82807 0.25905L12.5976 0.25905L6.97261 14.3274L3.20311 14.3274L0 2.01262C2.26561 3.22816 4.29685 5.67917 4.94138 7.91098L5.332 9.86381ZM11.8163 14.3474L15.371 14.3474L17.6171 0.25905L14.0429 0.25905L11.8163 14.3474ZM37.4802 0.25905L34.7264 0.25905C33.867 0.25905 33.2225 0.518099 32.8514 1.43474L27.5389 14.3673L31.2889 14.3673C31.2889 14.3673 31.9139 12.6337 32.0311 12.255L36.6209 12.255C36.7185 12.7532 37.0506 14.3673 37.0506 14.3673L40.3513 14.3673L37.4802 0.25905ZM34.492 5.42012C34.492 5.42012 33.3592 8.54864 33.0662 9.36564L36.0349 9.36564C35.8982 8.64827 35.2146 5.28063 35.2146 5.28063L34.9607 4.06509C34.8512 4.40042 34.6885 4.8596 34.5862 5.14834L34.5861 5.14855L34.5859 5.14929C34.5242 5.32328 34.4846 5.4351 34.492 5.42012Z"
                transform="translate(3.814327 0.1773327)"
                id="Shape"
                fill="#00579F"
                fill-rule="evenodd"
                stroke="none"
              />
              <path
                d="M5.80075 0L0.0585934 0L0 0.278977C4.49216 1.45466 7.46089 4.26436 8.67182 7.65193L7.42183 1.17569C7.22652 0.278977 6.58199 0.0199269 5.80075 0L5.80075 0Z"
                transform="translate(0.0838806 0.4363824)"
                id="path17"
                fill="#FAA61A"
                stroke="none"
              />
            </g>
          </svg>
          <p>VISA International</p>
        </div>
        <div class="item-payment">
          <svg
            width="27px"
            height="16px"
            viewBox="0 0 27 16"
            version="1.1"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="pay-mastercard">
              <path
                d="M7.07468 12.3141L0 12.3141L0 0L7.07468 0L7.07468 12.3141Z"
                transform="translate(9.481482 1.700741)"
                id="Shape"
                fill="#FF5F00"
                stroke="none"
              />
              <path
                d="M9.97447 7.80773C9.97447 10.3102 11.157 12.5624 13.0594 14.0139C11.6712 15.0649 9.92306 15.7156 8.07213 15.7156C3.59904 15.7156 0 12.2121 0 7.85778C0 3.50347 3.59904 0 8.07213 0C9.97447 0 11.6712 0.650644 13.0594 1.70168C11.157 3.05302 9.97447 5.30525 9.97447 7.80773"
                id="Shape"
                fill="#EB001B"
                stroke="none"
              />
              <path
                d="M13.0594 7.80878C13.0594 3.48162 9.46033 0 4.98724 0C3.08489 0 1.3882 0.646587 0 1.69107C1.90235 3.13346 3.08489 5.32191 3.08489 7.85852C3.08489 10.3951 1.90235 12.5836 0 14.026C1.3882 15.0705 3.1363 15.717 4.98724 15.717C9.46033 15.6176 13.0594 12.1359 13.0594 7.80878"
                transform="translate(13.06074 -2.4869E-14)"
                id="Shape"
                fill="#F79E1B"
                stroke="none"
              />
            </g>
          </svg>
          <p>Mastercard Worldwide</p>
        </div>
      </div>

      <p class="section-text">
        Для оплаты покупки Вы будете перенаправлены на платежный шлюз ПАО
        "Сбербанк России" для ввода реквизитов Вашей карты. Пожалуйста,
        приготовьте Вашу пластиковую карту заранее. Соединение с платежным
        шлюзом и передача информации осуществляется в защищенном режиме с
        использованием протокола шифрования SSL. В случае если Ваш банк
        поддерживает технологию безопасного проведения интернет-платежей
        Verified By Visa или MasterCard Secure Code для проведения платежа также
        может потребоваться ввод специального пароля. Способы и возможность
        получения паролей для совершения интернет-платежей Вы можете уточнить в
        банке, выпустившем карту.
      </p>

      <p class="section-text">
        Настоящий сайт поддерживает 256-битное шифрование. Конфиденциальность
        сообщаемой персональной информации обеспечивается ПАО "Сбербанк России".
        Введенная информация не будет предоставлена третьим лицам за исключением
        случаев, предусмотренных законодательством РФ. Проведение платежей по
        банковским картам осуществляется в строгом соответствии с требованиями
        платежных систем Visa Int. и MasterCard Europe Sprl.
      </p>
      <h2 class="underline-text">Безналичный расчет</h2>

      <p class="section-text">
        Оплата заказа производится по выставленному счету (только для
        юридических лиц). Счет формируется автоматически при оформлении заказа.
        Если клиент не может завершить оформление заказа на сайте, для
        выставления счета необходимо связаться с менеджером по тел. +7 (495)
        971-73-02.
      </p>

      <h2 class="underline-text">Возврат оплаты</h2>

      <p class="section-text">
        Возврат денежных средств на услугу осуществляется в течении 14 дней с
        даты оплаты картой по заявлению клиента с указанием причины возврата
        денежных средств, направленному компании. Компания возвращает клиенту
        денежные средства за вычетом сумм комиссий банка.
      </p>
    </div>
  </div>
</template>

<script>
import { useHead } from '@vueuse/head'
export default {
  name: "payment",
    setup() {
    useHead({
      title:
        "Как оплатить"
    })
  }
};
</script>

<style lang="scss" scoped>
.payment-page-wrapper {
  width: 100%;
  padding: 121px 0 50px;
  font-size: 17px;
  line-height: 24px;
  color: #414352;

  .container {
    position: relative;
    margin: 0 auto;
    padding: 0;
    max-width: 1170px;
  }

  .head-text {
    font-size: 24px;
    line-height: 1.2;
    margin-bottom: 30px;
  }

  h1 {
    font-size: 36px;
    line-height: 1.2;
    margin-bottom: 20px;
  }

  h2 {
    font-size: 28px;
    line-height: 1.2;
    margin: 30px 0 15px;
  }

  p {
    margin: 0 0 15px;
  }

  ul,
  ol {
    margin: 0 0 15px;
    padding: 0 0 0 40px;
  }

  .payment-methods {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 20px;

    .item-payment {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 20px 0;

      svg {
        display: block;
        height: 35px;
        width: auto;
        margin-bottom: 10px;
      }

      p {
        margin-bottom: 0;
      }
    }
  }

  @media (max-width: 1024px) {
    padding: 30px 0 30px;

    .container {
      padding: 0 15px;
    }

    h1 {
      font-size: 24px;
    }
    h2 {
      font-size: 20px;
    }
  }
}
</style>